import { selectRootSliceCAS } from '../../../Main/selectors';
import { ROOT_SLICE } from '../constants';

export const selectThisSlice = state => { 
  const slice = selectRootSliceCAS(state);
  if (slice[ROOT_SLICE]) { 
    return slice[ROOT_SLICE];
  }

  // else assume state is already customer app settings.
  return state;
};


/**
 * META
 */

export const selectCustomer = (state) => {
	const slice = selectThisSlice(state);
  const { customer } = slice;
	return customer;
}

export const selectAnalysisPageCustomer = (state) => {
	const slice = selectThisSlice(state);
  const { analysisPageCustomer } = slice;
	return analysisPageCustomer;
}

export const selectCustomerSettingsSource = (state) => {
	const slice = selectThisSlice(state);
  const { customerSettingsSource } = slice;
	return customerSettingsSource;
}

export const selectIsLoading = (state) => { 
	const slice = selectThisSlice(state);
  const { isLoadingCustomerSettingsSource } = slice;
	return !!isLoadingCustomerSettingsSource;  
}

export const selectIsLoaded = (state) => { 
	const slice = selectThisSlice(state);
  const { isLoadedCustomerSettingsSource } = slice;
	return !!isLoadedCustomerSettingsSource;  
}



/**
 * IN APP REPORTS
 */

export const selectHasActionPlans = (state) => {
	const slice = selectThisSlice(state);
  const { settings: { enableActionPlan } } = slice;
	return !!enableActionPlan;
}

export const selectHasClosureReports = (state) => {
	const slice = selectThisSlice(state);
  const { settings: { enableClosureReport } } = slice;
	return !!enableClosureReport;
}


/**
 * TABS & LISTS PAGE - LINE LISTS
 */

export const selectHasTabLinesCardView = state => {
	const slice = selectThisSlice(state);
  const { settings: { enableLinesListCardsView } } = slice;
	return !!enableLinesListCardsView;
};

export const selectHasTabLinesTableView = state => {
	const slice = selectThisSlice(state);
  const { settings: { enableLinesListTableView } } = slice;
	return !!enableLinesListTableView;
};

export const selectHasTabMyProjects = state => {
	const slice = selectThisSlice(state);
  const { settings: { enableMyProjects } } = slice;
	return !!enableMyProjects;
};


/**
 * TABS & LISTS PAGE - REPORTS LIST
 */

export const selectHasTabReports = state => {
	const slice = selectThisSlice(state);
  const { settings: { enableReports } } = slice;
	return !!enableReports;
}

export const selectHasTabCustomReportsV1 = (state) => {
	const slice = selectThisSlice(state);
  const { settings: { enableReportListActionPlanAndClosureV1 } } = slice;
	return !!enableReportListActionPlanAndClosureV1;
}

export const selectHasTabCustomReportsV2 = (state) => {
	const slice = selectThisSlice(state);
  const { settings: { enableReportListActionPlanAndClosureV2 } } = slice;
	return !!enableReportListActionPlanAndClosureV2;
}

export const selectHasTabReportPercentPassing = (state) => {
	const slice = selectThisSlice(state);
  const { settings: { enableReportPercentPassing } } = slice;
	return !!enableReportPercentPassing;
}

export const selectHasTabReportTotalMiles = (state) => {
	const slice = selectThisSlice(state);
  const { settings: { enableReportTotalMiles } } = slice;
	return !!enableReportTotalMiles;
}


/**
 * MISCELLANEOUS SETTINGS
 */


export const selectHasSharedState = (state) => {
	const slice = selectThisSlice(state);
  const { settings: { enableSharedStateForAnalysisAndReports } } = slice;
	return !!enableSharedStateForAnalysisAndReports;
}

export const selectHasBehaviorAutoRemoveIliData = (state) => {
	const slice = selectThisSlice(state);
  const { settings: { autoRemoveIliData } } = slice;
	return !!autoRemoveIliData;
}

export const selectHasFeatureClosureCopyActionPlan = (state) => {
	const slice = selectThisSlice(state);
  const { settings: { featureClosureCopyActionPlan } } = slice;
	return !!featureClosureCopyActionPlan;
}


export default {
  selectThisSlice,
  selectCustomer,
  selectAnalysisPageCustomer,
  selectCustomerSettingsSource,
  selectIsLoading,
  selectIsLoaded,

  selectHasActionPlans,
  selectHasClosureReports,
  selectHasTabMyProjects,

  selectHasTabLinesCardView,
  selectHasTabLinesTableView,

  selectHasTabReports,
  selectHasTabCustomReportsV1,
  selectHasTabCustomReportsV2,
  selectHasTabReportPercentPassing,
  selectHasTabReportTotalMiles,

  selectHasSharedState,
  selectHasBehaviorAutoRemoveIliData,
  selectHasFeatureClosureCopyActionPlan,
};
