import {
	removeChartBelowCriterions,
	thunkComputeBelowCriterions
} from '../../ChartsBelowCriterions/actions';
import {
	selectThreshold,
	selectUseShadow,
	selectUseThreshold,
	selectUseSawtooth,
	selectUseNormalizedDepol,
	selectUseInterpolatedDepol,
	selectSeriesVisibility,
	selectShowDecimatedData
} from '../selectors';
import {
	selectCustomExceptions,
	selectRemediationGroupRanges
} from '../../../../../../_reusable/ScanAppAndReportStates/ChartsRemediations/selectors';
import {
	MESSAGE_KEY_TOGGLE_ON,
	MESSAGE_KEY_TOGGLE_OFF,
	MESSAGE_KEY_TOGGLE_SHADOW,
	MESSAGE_KEY_CHANGE_THRESHOLD,
	MESSAGE_KEY_CHANGE_DEPOL
} from '../ConfirmUndoQueue.constants';

import { findGroup, findGroupByReading } from '../util';
import { thunkClearCustomGroupRanges } from '../../../../../../_reusable/ScanAppAndReportStates/ChartsRemediations/actions';
import { getSurveyIdFromChartId } from '../../../../../../../utils/chart';

import ConfirmUndoQueue from '../ConfirmUndoQueue';
import {
	setChartThresholds,
	setChartUseThreshold,
	setChartUseShadow,
	setChartUseSawtooth,
	setChartUseNormalizedDepol,
	setShowConfirmationForCustomGroups,
	setConfirmationMessageKey,
	incrementInputThresholdResetToken,
	incrementSeriesVisibilityResetToken,
	setChartUseInterpolatedDepol,
	setChartSurveys,
	addChartSurvey,
	removeChartSurvey,
	setSelectedRemediationGroup,
	setSeriesVisibility,
	setChartShowDecimatedData
} from './actions';
import { thunkComputeAllShowDecimatedData } from './thunks.computeAllShowDecimatedData';

// eslint-disable-next-line no-unused-vars
const thunkSetChartPendingThresholds = (chartId, thresholds) => (
	dispatch,
	getState
) => {
	const { app } = getState();
	const currThreshold = selectThreshold(app, { chartId });
	const newThreshold = thresholds instanceof Array ? thresholds[0] : thresholds;

	if (currThreshold === newThreshold) {
		// do nothing;
		return;
	}

	// const hasCustomRemediationGroups = false;
	// const hasRemediationsAssigned = false;

	dispatch(setChartThresholds(chartId, thresholds));
};

const thunkSetChartThresholds = (chartId, thresholds) => (
	dispatch,
	getState
) => {
	const { app } = getState();
	const currThreshold = selectThreshold(app, { chartId });
	const newThreshold = thresholds instanceof Array ? thresholds[0] : thresholds;

	if (currThreshold === newThreshold) {
		// do nothing;
		return;
	}

	dispatch(setChartThresholds(chartId, thresholds));

	if (selectUseThreshold(app, { chartId })) {
		dispatch(thunkComputeBelowCriterions(chartId));
		dispatch(thunkClearCustomGroupRanges(chartId));
	}
};

const hasCustomRemediations = customExceptions => {
	return customExceptions && customExceptions.length > 0;
};

const thunkSetChartThresholdsFromChart = (chartId, thresholds) => (
	dispatch,
	getState
) => {
	const { app } = getState();
	const customExceptions = selectCustomExceptions(app, chartId);

	ConfirmUndoQueue.clear();
	if (
		hasCustomRemediations(customExceptions) &&
		selectUseThreshold(app, { chartId })
	) {
		dispatch(setConfirmationMessageKey(chartId, MESSAGE_KEY_CHANGE_THRESHOLD));
		dispatch(setShowConfirmationForCustomGroups(chartId, true));
		ConfirmUndoQueue.addToConfirmQueue(() => {
			dispatch(thunkSetChartThresholds(chartId, thresholds));
		});
		ConfirmUndoQueue.addToUndoQueue(() => {
			dispatch(incrementInputThresholdResetToken(chartId));
		});
	} else {
		dispatch(thunkSetChartThresholds(chartId, thresholds));
	}
};

const thunkSetChartUseThreshold = (chartId, useThreshold) => (
	dispatch,
	getState
) => {
	const { app } = getState();
	if (useThreshold !== selectUseThreshold(app, { chartId })) {
		dispatch(setChartUseThreshold(chartId, useThreshold));
		dispatch(setChartUseShadow(chartId, !useThreshold));
		// @bug @todo @warning @wb - for some reason compute below criterions does not have access to most recent threshold warning
		dispatch(thunkComputeBelowCriterions(chartId));
		dispatch(thunkClearCustomGroupRanges(chartId));
	}
};

const thunkSetChartUseShadow = (
	chartId,
	useShadow,
	{ preventRecomputeBc = false } = {}
) => (dispatch, getState) => {
	const { app } = getState();
	if (useShadow !== selectUseShadow(app, { chartId })) {
		if (!preventRecomputeBc) {
			dispatch(removeChartBelowCriterions(chartId));
		}
		dispatch(setChartUseShadow(chartId, useShadow));
		dispatch(setChartUseThreshold(chartId, !useShadow));
		// @bug @todo @warning @wb - for some reason compute below criterions does not have access to most recent threshold warning
		if (!preventRecomputeBc) {
			dispatch(thunkComputeBelowCriterions(chartId));
			dispatch(thunkClearCustomGroupRanges(chartId));
		}
	}
};

const thunkSetChartUseNormalizedDepol = (chartId, useNormalizedDepol) => (
	dispatch,
	getState
) => {
	const { app } = getState();
	if (useNormalizedDepol !== selectUseNormalizedDepol(app, { chartId })) {
		dispatch(setChartUseNormalizedDepol(chartId, useNormalizedDepol));
		if (selectUseShadow(app, { chartId })) {
			dispatch(thunkComputeBelowCriterions(chartId));
			dispatch(thunkClearCustomGroupRanges(chartId));
		}
	}
};

const thunkSetChartUseNormalizedDepolFromChart = (
	chartId,
	useNormalizedDepol
) => (dispatch, getState) => {
	const { app } = getState();
	const customExceptions = selectCustomExceptions(app, chartId);

	ConfirmUndoQueue.clear();
	if (
		hasCustomRemediations(customExceptions) &&
		selectUseShadow(app, { chartId })
	) {
		dispatch(setConfirmationMessageKey(chartId, MESSAGE_KEY_CHANGE_DEPOL));
		dispatch(setShowConfirmationForCustomGroups(chartId, true));
		ConfirmUndoQueue.addToConfirmQueue(() => {
			dispatch(thunkSetChartUseNormalizedDepol(chartId, useNormalizedDepol));
		});
	} else {
		dispatch(thunkSetChartUseNormalizedDepol(chartId, useNormalizedDepol));
	}
};

const thunkSetChartUseInterpolatedDepol = (chartId, useInterpolatedDepol) => (
	dispatch,
	getState
) => {
	const { app } = getState();
	if (useInterpolatedDepol !== selectUseInterpolatedDepol(app, { chartId })) {
		dispatch(setChartUseInterpolatedDepol(chartId, useInterpolatedDepol));
		if (selectUseShadow(app, { chartId })) {
			dispatch(thunkComputeBelowCriterions(chartId));
			dispatch(thunkClearCustomGroupRanges(chartId));
		}
	}
};

const thunkSetChartUseInterpolatedDepolFromChart = (
	chartId,
	useInterpolatedDepol
) => (dispatch, getState) => {
	const { app } = getState();
	const customExceptions = selectCustomExceptions(app, chartId);

	ConfirmUndoQueue.clear();
	if (
		hasCustomRemediations(customExceptions) &&
		selectUseShadow(app, { chartId })
	) {
		dispatch(setConfirmationMessageKey(chartId, MESSAGE_KEY_CHANGE_DEPOL));
		dispatch(setShowConfirmationForCustomGroups(chartId, true));
		ConfirmUndoQueue.addToConfirmQueue(() => {
			dispatch(
				thunkSetChartUseInterpolatedDepol(chartId, useInterpolatedDepol)
			);
		});
	} else {
		dispatch(thunkSetChartUseInterpolatedDepol(chartId, useInterpolatedDepol));
	}
};

const thunkSetSelectedRemediationGroup = (chartId, paramGroup) => (
	dispatch,
	getState
) => {
	const { app } = getState();
	const remediationGroupRanges = selectRemediationGroupRanges(app, chartId);
	const group = findGroup(remediationGroupRanges, paramGroup) || undefined;

	if (remediationGroupRanges && group) {
		const groupIndex = remediationGroupRanges.indexOf(group);
		dispatch(setSelectedRemediationGroup(chartId, group, groupIndex));
	} else {
		dispatch(setSelectedRemediationGroup(chartId, undefined, undefined));
	}
};

const thunkSetSelectedRemediationGroupByReading = (chartId, reading) => (
	dispatch,
	getState
) => {
	const { app } = getState();
	const remediationGroupRanges = selectRemediationGroupRanges(app, chartId);
	const group =
		findGroupByReading(remediationGroupRanges, reading) || undefined;
	const groupIndex = remediationGroupRanges.indexOf(group);

	dispatch(setSelectedRemediationGroup(chartId, group, groupIndex));
};

const thunkSetChartSurveys = (chartId, surveys) => dispatch => {
	dispatch(setChartSurveys(chartId, surveys));
};

const thunkAddChartSurvey = (chartId, survey) => dispatch => {
	dispatch(addChartSurvey(chartId, survey));
};

const thunkRemoveChartSurvey = (chartId, survey) => dispatch => {
	dispatch(removeChartSurvey(chartId, survey));
};

const UNKNOWN = 'UNKNOWN';
const typeBySeriesName = {
	on: 'ON',
	off: 'OFF',
	sawtooth: 'SAWTOOTH',
	['-100mv']: 'SHADOW',
	depol: 'DEPOL',
	'below criterion': 'BELOW CRITERION',
	'remediation groups': 'REMEDIATION GROUPS',
	'selected remediation group': 'SELECTED REMEDIATION GROUP'
};

const simpleSeriesTypesToToggle = [
	'BELOW CRITERION',
	'REMEDIATION GROUPS',
	'SELECTED REMEDIATION GROUP'
];

const getSeriesTypeByName = name => {
	const key = (name || '').trim().toLowerCase();
	return typeBySeriesName[key] || UNKNOWN;
};

const _shouldRecomputeBelowCriterions = (chartId, type, getState) => {
	if (type === 'SHADOW' || type === 'OFF') {
		return true;
	}
	if (type === 'ON' || type === 'SAWTOOTH') {
		const isOnOff =
			chartId.indexOf('ON-OFF') > -1 || chartId.indexOf('ON_OFF') > -1;

		if (isOnOff) {
			const surveyId = getSurveyIdFromChartId(chartId);
			const isOffVisible = selectSeriesVisibility(
				getState().app,
				{ chartId },
				{
					dataSetKey: surveyId,
					propKey: 'off'
				}
			);
			return !isOffVisible;
		}
		return true;
	}
	return false;
};

const thunkSetSeriesVisibility = (
	chartId,
	props,
	{ preventRecomputeBc = false } = {}
) => (dispatch, getState) => {
	const { seriesName, isVisible } = props;
	const TYPE = getSeriesTypeByName(seriesName);
	// console.log({ chartId, seriesName, isVisible, TYPE });
	// debugger;

	if (simpleSeriesTypesToToggle.indexOf(TYPE) > -1) {
		dispatch(
			setSeriesVisibility(chartId, props, { overwriteChildStore: true })
		);
	} else if (TYPE === 'SHADOW') {
		dispatch(setSeriesVisibility(chartId, props));
		dispatch(
			thunkSetChartUseShadow(chartId, isVisible, { preventRecomputeBc })
		);
	} else if (_shouldRecomputeBelowCriterions(chartId, TYPE, getState)) {
		if (!preventRecomputeBc) {
			dispatch(removeChartBelowCriterions(chartId));
		}
		dispatch(setSeriesVisibility(chartId, props));
		if (!preventRecomputeBc) {
			dispatch(thunkComputeBelowCriterions(chartId));
			dispatch(thunkClearCustomGroupRanges(chartId));
		}
	} else {
		dispatch(setSeriesVisibility(chartId, props));
	}
};

const thunkSetSeriesVisibilityFromChart = (chartId, props) => (
	dispatch,
	getState
) => {
	const { app } = getState();
	const customExceptions = selectCustomExceptions(app, chartId);
	const { seriesName } = props;
	const TYPE = getSeriesTypeByName(seriesName);
	const blnHasCustomRemediations = hasCustomRemediations(customExceptions);
	const shouldRecomputeBc = _shouldRecomputeBelowCriterions(
		chartId,
		TYPE,
		getState
	);

	const selectMessageKeyByType = paramType => {
		const ON = 'ON';
		const OFF = 'OFF';
		const SHADOW = 'SHADOW';

		const type =
			typeof paramType === 'string' ? paramType.toUpperCase() : paramType;

		switch (type) {
			case ON:
				return MESSAGE_KEY_TOGGLE_ON;
			case OFF:
				return MESSAGE_KEY_TOGGLE_OFF;
			case SHADOW:
				return MESSAGE_KEY_TOGGLE_SHADOW;
			default:
				return undefined;
		}
	};

	ConfirmUndoQueue.clear();
	if (blnHasCustomRemediations && shouldRecomputeBc) {
		dispatch(setConfirmationMessageKey(chartId, selectMessageKeyByType(TYPE)));
		dispatch(setShowConfirmationForCustomGroups(chartId, true));
		ConfirmUndoQueue.addToConfirmQueue(() => {
			dispatch(thunkSetSeriesVisibility(chartId, props));
		});
		ConfirmUndoQueue.addToUndoQueue(() => {
			dispatch(incrementSeriesVisibilityResetToken(chartId));
		});
	} else {
		dispatch(thunkSetSeriesVisibility(chartId, props));
	}
};

const thunkSetChartUseSawtooth = (
	chartId,
	surveyId,
	useSawtooth,
	{ forceRecomputeBc = false } = {}
) => (dispatch, getState) => {
	const { app } = getState();
	if (useSawtooth !== selectUseSawtooth(app, { chartId })) {
		if (forceRecomputeBc) {
			dispatch(removeChartBelowCriterions(chartId));
		}
		dispatch(
			thunkSetSeriesVisibility(
				chartId,
				{
					dataSetKey: surveyId,
					seriesName: 'On',
					propKey: 'on',
					isVisible: true
				},
				{ preventRecomputeBc: true }
			)
		);
		dispatch(
			thunkSetSeriesVisibility(
				chartId,
				{
					dataSetKey: surveyId,
					seriesName: 'Sawtooth',
					propKey: 'sawtooth',
					isVisible: true
				},
				{ preventRecomputeBc: true }
			)
		);
		dispatch(
			thunkSetSeriesVisibility(
				chartId,
				{
					dataSetKey: surveyId,
					seriesName: 'Off',
					propKey: 'off',
					isVisible: true
				},
				{ preventRecomputeBc: true }
			)
		);
		dispatch(setChartUseSawtooth(chartId, useSawtooth));
		if (forceRecomputeBc) {
			dispatch(thunkComputeBelowCriterions(chartId));
			dispatch(thunkClearCustomGroupRanges(chartId));
		}
	}
};

const thunkSetChartUseSawtoothFromChart = (chartId, surveyId, useSawtooth) => (
	dispatch,
	getState
) => {
	const { app } = getState();
	if (useSawtooth !== selectUseSawtooth(app, { chartId })) {
		const customExceptions = selectCustomExceptions(app, chartId);
		const blnHasCustomRemediations = hasCustomRemediations(customExceptions);
		const isOffVisible = selectSeriesVisibility(
			getState().app,
			{ chartId },
			{
				dataSetKey: surveyId,
				propKey: 'off'
			}
		);
		const offTYPE = getSeriesTypeByName('Off');
		const shouldRecomputeBc =
			useSawtooth &&
			!isOffVisible &&
			_shouldRecomputeBelowCriterions(chartId, offTYPE, getState);

		ConfirmUndoQueue.clear();
		if (blnHasCustomRemediations && shouldRecomputeBc) {
			dispatch(setConfirmationMessageKey(chartId, undefined));
			dispatch(setShowConfirmationForCustomGroups(chartId, true));
			ConfirmUndoQueue.addToConfirmQueue(() => {
				dispatch(
					thunkSetChartUseSawtooth(chartId, surveyId, useSawtooth, {
						forceRecomputeBc: true
					})
				);
			});
		} else {
			dispatch(
				thunkSetChartUseSawtooth(chartId, surveyId, useSawtooth, {
					forceRecomputeBc: false
				})
			);
		}
	}
};

const thunkConfirmPendingBcComputeChange = () => () => {
	ConfirmUndoQueue.executeConfirmQueue();
};
const thunkUndoPendingBcComputeChange = () => () => {
	ConfirmUndoQueue.executeUndoQueue();
};

const thunkSetChartShowDecimatedData = (chartId, showDecimatedData) => (
	dispatch,
	getState
) => {
	const currentVal = selectShowDecimatedData(getState().app, { chartId });
	if (currentVal !== showDecimatedData) {
		dispatch(setChartShowDecimatedData(chartId, showDecimatedData));
	}
};

// eslint-disable-next-line import/prefer-default-export
export {
	thunkSetChartThresholds,
	thunkSetChartThresholdsFromChart,
	thunkSetChartUseThreshold,
	thunkSetChartUseShadow,
	thunkSetChartUseSawtooth,
	thunkSetChartUseSawtoothFromChart,
	thunkSetChartUseNormalizedDepol,
	thunkSetChartUseInterpolatedDepol,
	thunkSetChartSurveys,
	thunkAddChartSurvey,
	thunkRemoveChartSurvey,
	thunkSetSelectedRemediationGroup,
	thunkSetSelectedRemediationGroupByReading,
	thunkSetSeriesVisibility,
	thunkSetSeriesVisibilityFromChart,
	thunkConfirmPendingBcComputeChange,
	thunkUndoPendingBcComputeChange,
	thunkSetChartUseNormalizedDepolFromChart,
	thunkSetChartUseInterpolatedDepolFromChart,
	thunkComputeAllShowDecimatedData,
	thunkSetChartShowDecimatedData
};
