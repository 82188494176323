import React, { useMemo } from 'react';
import HelperRenderStringList from './HelperRenderStringList';
import TransLabel from '../../../common/TransLabel';

/** ****************************
 * HELPERS
 */

const EMPTY_OBJECT = {};
const DEFAULT_HEADER = 'Targa Group Status';
const PROP_KEY = 'targaGroupStatusCounts';

/** ****************************
 * MAIN
 */

const TH = ({ header = DEFAULT_HEADER }) => <TransLabel text={header} abbrv/>;

const TD = ({ item = EMPTY_OBJECT }) => {
  const value = useMemo(() => { 
    const _map = (item || {})[PROP_KEY] || {};
    const statuses = Object.keys(_map);
    return statuses.map(s => `${_map[s]} - ${s}`).join('~');
  }, [item]);

	return (
		<td>
			<HelperRenderStringList strList={value} direction="vertical" />
		</td>
	);
};

const ColTargaGroupStatusesWithCounts = {
	TH,
	TD,
	CSV_PROP: PROP_KEY,
	DEFAULT_HEADER
};

export default ColTargaGroupStatusesWithCounts;
