/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import { assumeCognitoUser } from '../../../cognito';
import scanlineUtils from '../../../../scanline/scanlineUtils';
import getAipUrls from '../../../../config/getAipUrls';
import { renderAsText, asyncTimeout } from './helpers';

const aipUrls = getAipUrls();

const S3_ACTION_DESC = 'getObject -> s3.getObject';

const emailCustomerSupport = ({ payload, error, attemptCount }) => {
	const title = `AIP - request "${S3_ACTION_DESC}" failed (attempt ${attemptCount})`;

  try { 
    const errorTxt = renderAsText(error);
    const payloadTxt = renderAsText(payload);
    const isErrorLoadingS3CachedScanReadings = payloadTxt.indexOf('ControllerJSON.json') > -1;
  
    const ignoreError = isErrorLoadingS3CachedScanReadings;
  
    if (!ignoreError) {
      scanlineUtils.emailCustomerSupport(
        {
          appendToSubject: title,
          content: `
            <div>
              <h2>${title}</h2>
              <div>Error: ${errorTxt}</div>
              <div>Payload: ${payloadTxt}</div>
            <div>
          `,
          docType: 'HTML'
        },
        { ignoreError: true }
      );
    };
  } catch (e) { 
    // ignore caught errors for now
  }
}

const _getObject = async (s3, params, attemptCount = 1, ignoreError) => {
	const retryOnFail = attemptCount === 1;
	return s3
		.getObject(params)
		.promise()
		.catch(async err => {
			if (!ignoreError) {
				emailCustomerSupport({
					payload: params,
					error: err,
					attemptCount
				});
			}
			if (retryOnFail) {
				await asyncTimeout(300);
				return _getObject(s3, params, attemptCount + 1, ignoreError);
			}
			throw err;
		});
};

export const getObject = async (Key, opts, enableFallback = false) => {
	const { bucket, versioned = false, versionId, ignoreError = false } = opts;
	const Bucket = bucket || versioned ? aipUrls.versionedBucket : aipUrls.bucket;
	const params = {
		Bucket,
		Key,
		VersionId: versionId
	};

	const AWS = await assumeCognitoUser();
	const s3 = new AWS.S3({ apiVersion: '2006-03-01' });

	return _getObject(s3, params, enableFallback ? 1 : 0, ignoreError);
};
